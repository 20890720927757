<template>
  <div>
    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :tabindex="props.column.fieldInfo.meta.sortPosition"
      :aria-label="props.column.fieldInfo.name"
      :class="['w-full']"
      inputClass="form-select"
      :loading="isItemsLoading"
      :placeholder="
        isItemsLoading ? $t('items_is_loading') : $t('field_select_empty_selection')
      "
      :showClear="true"
      :disabled="isItemsLoading"
      :modelValue="props.filter.value"
      @update:modelValue="onUpdateModelValue"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />

            <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
          </div>
        </div>
      </template>

      <template #value="slotProps">
        <div
          v-if="!!itemsHashMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between"
        >
          <RenderTemplate
            :collectionName="relationInfo?.relatedCollection ?? ''"
            :item="itemsHashMap[slotProps.value]"
            :fieldInfo="props.column.fieldInfo"
            :defaultTemplate="defaultRenderTemplate ?? ''"
          />
        </div>

        <template v-else>
          <span>{{ slotProps.placeholder }}</span>
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!itemsHashMap[slotProps.option.value]"
          :collectionName="relationInfo?.relatedCollection ?? ''"
          :item="itemsHashMap[slotProps.option.value]"
          :fieldInfo="props.column.fieldInfo"
          :defaultTemplate="defaultRenderTemplate"
        ></RenderTemplate>

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </PDropdown>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import PDropdown from "primevue/dropdown";
  import { useDropdownItemsController } from "~/service/dropdown-items/composables/useDropdownItemsController";
  import { RenderTemplate } from "~/entities/render-template";
  import { useRelationM2O } from "~/api/relations/composables/useRelationsM2O";
  import { type IItem, type ItemID } from "~/entities/item";
  import {
    ColumnFilterProps,
    ColumnFilterEmits,
    ColumnFilterMeta,
  } from "../../lib/interfaces";

  type LinearModelValue = (string | number)[];

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collectionName),
    computed(() => props.column.fieldInfo),
  );

  const {
    items,
    options,
    search,
    isLoading: isItemsLoading,
    virtualScrollerOptions,
    searchEventListeners,
    dropdownEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.column.fieldInfo),
  );

  const itemsHashMap = computed<Record<ItemID, IItem>>(() =>
    items.value.reduce<Record<ItemID, IItem>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;

      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );

  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = props.column.fieldInfo.meta.displayOptions?.template;

    if (!!fieldTemplate) return fieldTemplate;
    const collectionTemplate = relatedCollection.value?.meta.displayTemplate;

    const primaryField = relatedCollection.value?.getPrimaryFieldInfo();

    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });

  const onUpdateModelValue = (event: LinearModelValue): void => {
    emit("update:filter", {
      filterName: props.column.name,
      data: event,
      filterMeta: {
        [ColumnFilterMeta.API_FILTER_NAME]: props.column.fieldInfo.name,
      },
      immediate: true,
    });
  };
</script>

<style scoped></style>
